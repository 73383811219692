import React, { JSX } from 'react';
import { uid } from 'react-uid';
import { AppModal, CopyButton } from '@ankr.com/raas-ui';
import { Box, Button } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { RollupRPCEndpointsModalTranslation } from './translation';
import { useRollupRPCEndpointsModalStyles } from './useRollupRPCEndpointsModalStyles';

interface IRollupRPCEndpointsModalProps {
  RPCEndpoints: string[];
}

export function RollupRPCEndpointsModal({
  RPCEndpoints,
}: IRollupRPCEndpointsModalProps): JSX.Element {
  const { classes } = useRollupRPCEndpointsModalStyles();

  const { keys, t } = useTranslation(RollupRPCEndpointsModalTranslation);

  const {
    open: rollupRPCEndpointsModalOpen,
    handleClose: handleRPCEndpointsModalClose,
  } = useDialog(DialogId.RollupRPCEndpoints);

  return (
    <AppModal
      open={rollupRPCEndpointsModalOpen}
      onClose={handleRPCEndpointsModalClose}
      title={t(keys.title)}
      classes={{ paper: classes.modalContent }}
    >
      <Box className={classes.listBody}>
        {RPCEndpoints.map(endpoint => (
          <Box key={uid(endpoint)} className={classes.listRow}>
            <Box className={classes.listRowLeft} overflow="hidden">
              {endpoint}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <CopyButton text={endpoint} size="small" />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        <Button onClick={handleRPCEndpointsModalClose} size="large" fullWidth>
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
