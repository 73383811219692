import { Locale } from '../i18n';

export const dashboardTranslation = {
  [Locale.en]: {
    myRollups: {
      pageTitle: 'My Rollups',
      getStarted: 'How to get started',
    },
    rollupList: {
      network: 'Rollup',
      status: 'Status',
      DAL: 'Data Availability Layer',
      createdDate: 'Creation Date',
      until: 'Until {date}',
      nextPayment: 'Next Payment {date}',
    },
  },
};
