import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { mainApi } from '../../../../api';
import { Gateways } from '../../../../apiService';
import { GRADE, IDALItem, IDALItemApi } from '../../../RollupConst';

interface IGetDALsProps {
  grade?: GRADE;
  stack?: string;
}

export const { useGetDALsQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getDALs: build.query<IDALItem[], IGetDALsProps>({
      queryFn: queryFnNotifyWrapper<IGetDALsProps, never, IDALItem[]>(
        async ({ grade, stack }) => {
          if (grade && stack) {
            const { data } = await Gateways.noAuth.api.get(
              `/api/v1/data_availability_layers/${grade}/${stack}`,
              {
                method: 'GET',
              },
            );

            return {
              data: data.map((item: IDALItemApi) => ({
                description: item.description,
                icon: item.icon,
                title: item.title,
                uuid: item.uuid,
                comingSoon: item.coming_soon,
              })),
            };
          }
        },
      ),
    }),
  }),
});
