import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { mainApi } from '../../../../api';
import { Gateways } from '../../../../apiService';
import { IStackItem, IStackItemApi } from '../../../RollupConst';

export const { useGetStacksQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getStacks: build.query<IStackItem[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IStackItem[]>(async () => {
        const { data } = await Gateways.noAuth.api.get('/api/v1/stacks', {
          method: 'GET',
        });

        return {
          data: data.map((item: IStackItemApi) => ({
            description: item.description,
            icon: item.icon,
            title: item.title,
            uuid: item.uuid,
            comingSoon: item.coming_soon,
          })),
        };
      }),
    }),
  }),
});
