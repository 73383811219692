import BigNumber from 'bignumber.js';

import { mapDepositsForPlanFromApi } from '../../../../common/utils/mapDepositsFromApi';
import { IPlanItem, IPlanItemApi } from '../../../RollupConst';

export function mapPlanFromApi(plan: IPlanItemApi): IPlanItem {
  return {
    title: plan.title,
    uuid: plan.uuid,
    description: plan.description,
    grade: plan.grade,
    price: new BigNumber(plan.price),
    stack: plan.stack,
    deposits: mapDepositsForPlanFromApi(plan.deposits),
    settings: plan.settings?.map(setting => ({
      ...setting,
      price: new BigNumber(setting.price),
    })),
    optionalSettings: plan.optional_settings?.map(setting => ({
      ...setting,
      price: new BigNumber(setting.price),
    })),
  };
}

export function mapPlansFromApi(plans: IPlanItemApi[]): IPlanItem[] {
  return plans.map((plan: IPlanItemApi) => mapPlanFromApi(plan));
}
