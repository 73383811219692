/* eslint-disable camelcase */
import { Web3Address } from '@ankr.com/raas-types';
import BigNumber from 'bignumber.js';

export const GRADE = {
  testnet: 'testnet',
  mainnet: 'mainnet',
};
export type GRADE = (typeof GRADE)[keyof typeof GRADE];

export const SEQUENCER_MANAGEMENT_TYPE = {
  product: 'product',
  self: 'self',
};
export type SEQUENCER_MANAGEMENT_TYPE =
  (typeof SEQUENCER_MANAGEMENT_TYPE)[keyof typeof SEQUENCER_MANAGEMENT_TYPE];

export const GAS_TOKEN_TESTNET = {
  eth: 'eth',
};
export type GAS_TOKEN_TESTNET =
  (typeof GAS_TOKEN_TESTNET)[keyof typeof GAS_TOKEN_TESTNET];

export const GAS_TOKEN_MAINNET = {
  eth: 'eth',
  custom: 'custom',
};
export type GAS_TOKEN_MAINNET =
  (typeof GAS_TOKEN_MAINNET)[keyof typeof GAS_TOKEN_MAINNET];

export const GAS_TOKEN = {
  eth_holesky: 'eth_holesky',
  ...GAS_TOKEN_TESTNET,
  ...GAS_TOKEN_MAINNET,
};
export type GAS_TOKEN = (typeof GAS_TOKEN)[keyof typeof GAS_TOKEN];

export const ROLLUP_STATUS = {
  initializing: 'initializing',
  pending_deposit: 'pending_deposit',
  deploying_bridge: 'deploying_bridge',
  unpaid: 'unpaid',
  pending: 'pending',
  deployed: 'deployed',
  failed: 'failed',
};
export type ROLLUP_STATUS = (typeof ROLLUP_STATUS)[keyof typeof ROLLUP_STATUS];

export interface IStackItemApi {
  description: string;
  icon: string;
  title: string;
  uuid: string;
  coming_soon?: boolean;
}

export interface IStackItem {
  description: string;
  icon: string;
  title: string;
  uuid: string;
  comingSoon?: boolean;
}

export interface IDALItemApi {
  description: string;
  icon: string;
  title: string;
  uuid: string;
  coming_soon?: boolean;
}

export interface IDALItem {
  description: string;
  icon: string;
  title: string;
  uuid: string;
  comingSoon?: boolean;
}

export interface IPlanSettingItem {
  description: string;
  key: string;
  price: BigNumber;
  title: string;
  uuid: string;
  value: string | number | boolean;
}

export interface IFundRequestItem {
  address?: Web3Address;
  amount: BigNumber;
  token: string;
}

export interface IDepositItem {
  fundRequests: IFundRequestItem[];
  isCustom: boolean;
  parsedForUI: {
    amountsWithToken: string[];
    address?: Web3Address;
  };
}

export interface IDepositsForPlan {
  default?: IDepositItem;
  custom?: IDepositItem;
}

export interface IFundRequestItemApi {
  address?: string;
  amount: string;
  token: string;
}

export interface IDepositItemApi {
  fund_requests: IFundRequestItemApi[];
  is_custom: boolean;
}

export interface IDetailsItem {
  blockHeight?: number;
  rpcEndpoints?: string[];
  bridgeUrl?: string;
  explorerUrl?: string;
}

export interface IDetailsItemApi {
  block_height?: number;
  bridge_url?: string;
  explorer_url?: string;
  rpc_url?: string | string[];
}

export interface IPlanItemApi {
  uuid: string;
  title: string;
  description: string;
  price: number;
  deposits: IDepositItemApi[] | null;
  stack: IStackItem;
  grade: GRADE;
  settings?: IPlanSettingItem[];
  optional_settings?: null | IPlanSettingItem[];
}

export interface IPlanItem {
  title: string;
  uuid: string;
  description: string;
  grade: GRADE;
  price: BigNumber;
  deposits?: IDepositsForPlan;
  stack: IStackItem;
  settings?: IPlanSettingItem[];
  optionalSettings?: null | IPlanSettingItem[];
}

export interface IDeployRollupFormGeneralPayload {
  stack?: string;
  grade?: GRADE;
  dataAvailabilityLayer?: string;
}

export interface IDeployRollupFormSequencerPayload {
  sequencerManagementType?: SEQUENCER_MANAGEMENT_TYPE;
  sequencerAddress?: Web3Address;
  sequencerUrl?: string;
}

export interface IDeployRollupFormConfigurationPayload {
  chainId?: string | number;
  networkName?: string;
  gasToken?: GAS_TOKEN;
  gasTokenAddress?: Web3Address;
  optionalSettings?: string[];
}

export interface IDeployRollupFormSignaturePayload {
  signature?: string;
  userAddress?: Web3Address;
}

export interface IDeployRollupFormPlanPayload {
  planUuid?: string;
}

export interface IDeployRollupFormPayload
  extends IDeployRollupFormGeneralPayload,
    IDeployRollupFormSequencerPayload,
    IDeployRollupFormConfigurationPayload,
    IDeployRollupFormSignaturePayload,
    IDeployRollupFormPlanPayload {}

export const deployRollupInitialState: IDeployRollupFormPayload = {
  planUuid: '',
  stack: '',
  grade: '',
  dataAvailabilityLayer: '',
  sequencerManagementType: SEQUENCER_MANAGEMENT_TYPE.product,
  sequencerAddress: '',
  sequencerUrl: '',
  chainId: '',
  networkName: '',
  gasToken: GAS_TOKEN.eth,
  gasTokenAddress: '',
  optionalSettings: [],
};

export interface IDeployRollupFormApi {
  uuid?: string;
  chain_id?: number;
  data_availability_layer_uuid?: string;
  gas_token?: string;
  gas_token_address?: Web3Address;
  l1_chain?: string;
  network_name?: string;
  optional_settings?: string[];
  plan_uuid?: string;
  sequencer_address?: string;
  sequencer_management_type?: SEQUENCER_MANAGEMENT_TYPE;
  sequencer_url?: string;
  signature?: string;
  user_address?: Web3Address;
}

export interface IDeployRollupSubscribeResponseApi {
  payment_intent_id?: string;
  url?: string;
}

export interface IDeployRollupResponseApi {
  deployment?: IDeployRollupFormApi;
  subscription?: IDeployRollupSubscribeResponseApi;
}

export interface IRollupDeployedApi {
  created_at: string;
  paid_until: string;
  uuid: string;
  plan: IPlanItemApi;
  deposits: IDepositItemApi[] | null;
  optional_settings: null | string[];
  status: ROLLUP_STATUS;
  sequencer_management_type: SEQUENCER_MANAGEMENT_TYPE;
  sequencer_address?: Web3Address;
  sequencer_url?: string;
  chain_id: number;
  network_name: string;
  gas_token: GAS_TOKEN;
  gas_token_address?: Web3Address;
  l1_chain: string;
  data_availability_layer?: IDALItem;
  extendable: boolean;
  details?: IDetailsItemApi;
}

export interface IRollupDeployed {
  createdAt: Date;
  paidUntil?: Date;
  uuid: string;
  plan: IPlanItem;
  deposits?: IDepositItem;
  optionalSettings?: null | string[];
  status: ROLLUP_STATUS;
  sequencerManagementType?: SEQUENCER_MANAGEMENT_TYPE;
  sequencerAddress?: Web3Address;
  sequencerUrl?: string;
  chainId: number;
  networkName: string;
  gasToken: GAS_TOKEN;
  gasTokenAddress?: Web3Address;
  dataAvailabilityLayer?: IDALItem;
  extendable: boolean;
  l1Chain?: string;
  details?: IDetailsItem;
}
