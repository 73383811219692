import React, { JSX, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CardDialog, QueryLoadingCentered } from '@ankr.com/raas-ui';
import { isValidWebsiteUrl } from '@ankr.com/raas-utils';
import { LoadingButton, TextField } from '@ankr.com/ui';
import { Box, Container } from '@mui/material';

import { useTranslation } from '../../../i18n';
import { useGetAccountInfoQuery } from './api/getAccountInfo';
import { usePostAccountInfoMutation } from './api/postAccountInfo';
import { IAccountInfo } from './api/types';
import { accountInfoFormTranslation } from './translation';

interface IAccountInfoFormProps {
  showSubtitle?: boolean;
}

export function AccountInfoForm({
  showSubtitle,
}: IAccountInfoFormProps): JSX.Element {
  const { keys, t } = useTranslation(accountInfoFormTranslation);

  const [postAccountInfo, { isLoading: isAccountInfoPosting }] =
    usePostAccountInfoMutation();

  const {
    data: accountInfoData,
    isLoading: isAccountInfoLoading,
    isSuccess: isAccountInfoSuccess,
  } = useGetAccountInfoQuery();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm<IAccountInfo>({
    defaultValues: {
      email: '',
      companyName: '',
      companyInfo: '',
      companyTwitter: '',
    },
  });

  useEffect(() => {
    if (isAccountInfoSuccess) {
      if (accountInfoData?.email) {
        setValue('email', accountInfoData?.email, { shouldDirty: false });
      }
      if (accountInfoData?.companyName) {
        setValue('companyName', accountInfoData?.companyName, {
          shouldDirty: false,
        });
      }
      if (accountInfoData?.companyInfo) {
        setValue('companyInfo', accountInfoData?.companyInfo, {
          shouldDirty: false,
        });
      }
      if (accountInfoData?.companyTwitter) {
        setValue('companyTwitter', accountInfoData?.companyTwitter, {
          shouldDirty: false,
        });
      }
    }
  }, [
    accountInfoData?.companyInfo,
    accountInfoData?.companyName,
    accountInfoData?.companyTwitter,
    accountInfoData?.email,
    isAccountInfoSuccess,
    setValue,
  ]);

  const onSubmit = useCallback(
    async (payload: IAccountInfo) => {
      await postAccountInfo(payload);
    },
    [postAccountInfo],
  );

  return (
    <Container>
      <CardDialog
        title={t(keys.title)}
        subtitle={showSubtitle ? t(keys.subtitle) : ''}
        hideCloseButton
      >
        {isAccountInfoLoading ? (
          <QueryLoadingCentered />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={7}>
              <Controller
                name="email"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.emailHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    type="email"
                    label={t(keys.email)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Box>
            <Box mb={7}>
              <Controller
                name="companyName"
                rules={{
                  required: t('validation.required'),
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t(keys.companyName)}
                  />
                )}
              />
            </Box>
            <Box mb={7}>
              <Controller
                name="companyInfo"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.companyInfoHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={5}
                    label={t(keys.companyInfo)}
                  />
                )}
              />
            </Box>
            <Box mb={10}>
              <Controller
                name="companyTwitter"
                rules={{
                  validate: {
                    isValidURL: v =>
                      !v ||
                      (!!v && isValidWebsiteUrl(v ?? '')) ||
                      t('validation.invalid-url'),
                  },
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.twitterHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    type="url"
                    label={t(keys.companyTwitter)}
                  />
                )}
              />
            </Box>

            <LoadingButton
              loading={isAccountInfoPosting}
              disabled={!isDirty}
              type="submit"
              size="large"
              fullWidth
            >
              {t(keys.submitBtn)}
            </LoadingButton>
          </form>
        )}
      </CardDialog>
    </Container>
  );
}
