import { createGetaways } from '@ankr.com/auth';

import { IS_PROD } from '../../common/const/environment';

export const API_BASE_URL = IS_PROD
  ? 'https://api-raas.ankr.com'
  : 'https://raas.dev.staking.ankr.com';

export const Gateways: ReturnType<typeof createGetaways> = createGetaways({
  baseURL: API_BASE_URL,
});
