import { Locale } from '../../../i18n';

export const rollupStatusChipTranslation = {
  [Locale.en]: {
    status: {
      initializing: 'Initializing',
      pendingDeposit: 'Pending Deposit',
      unpaid: 'Unpaid',
      pending: 'Pending',
      deployingBridge: 'Deploying Bridge',
      deployed: 'Deployed',
      failed: 'Failed',
    },
  },
};
