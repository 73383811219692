import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { truncateWalletAddress } from '@ankr.com/raas-utils';
import { LoadingButton } from '@ankr.com/ui';
import { Alert, Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import stripeLogoUrl from '../../../../../common/assets/stripeLogo.png';
import { GradeChip } from '../../../../../common/components/GradeChip';
import { useDepositsForPlan } from '../../../../../common/hooks/useDepositsForPlan';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { getToken } from '../../../../../common/utils/getToken';
import { useTranslation } from '../../../../../i18n';
import {
  GRADE,
  IDeployRollupFormPayload,
  IPlanItem,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../../RollupConst';
import { useGetDALsQuery } from '../../api/getDALs';
import { useGetStacksQuery } from '../../api/getStacks';
import { usePostRollupDeployMutation } from '../../api/postRollupDeploy';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

interface IDeployRollupConfirmModalProps {
  rollupDeployState: IDeployRollupFormPayload;
  currentPlan?: IPlanItem;
}

export function DeployRollupConfirmModal({
  rollupDeployState,
  currentPlan,
}: IDeployRollupConfirmModalProps): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { data: stacks } = useGetStacksQuery();

  const currentStack = useMemo(
    () => stacks?.find(stack => stack.uuid === rollupDeployState.stack),
    [rollupDeployState.stack, stacks],
  );

  const { data: DALs } = useGetDALsQuery(
    {
      grade: rollupDeployState.grade,
      stack: rollupDeployState.stack,
    },
    {
      skip: !rollupDeployState.grade || !rollupDeployState.stack,
    },
  );

  const currentDAL = useMemo(
    () =>
      DALs?.find(DAL => DAL.uuid === rollupDeployState.dataAvailabilityLayer),
    [DALs, rollupDeployState.dataAvailabilityLayer],
  );

  const currentRPCAmount = useMemo(
    () => currentPlan?.settings?.find(setting => setting.key === 'rpc_amount'),
    [currentPlan?.settings],
  );

  const { imageSrc: gasTokenLogo, name: gasTokenName } = getToken({
    value: rollupDeployState.gasToken,
  });

  const depositAmountByGasToken = useDepositsForPlan({
    deposits: currentPlan?.deposits,
    gasTokenType: rollupDeployState.gasToken,
  });

  const [
    postRollupDeployment,
    {
      data: postRollupDeploymentResult,
      isLoading: isPostRollupDeploymentLoading,
      isSuccess: isPostRollupDeploymentSuccess,
    },
  ] = usePostRollupDeployMutation();

  const { open: rollupConfirmOpen, handleClose: handleRollupConfirmClose } =
    useDialog(DialogId.RollupConfirm);

  const { handleOpen: handleRollupTestnetProveOpen } = useDialog(
    DialogId.RollupTestnetProve,
  );

  const handleConfirm = useCallback(async () => {
    if (rollupDeployState.grade === GRADE.mainnet) {
      await postRollupDeployment(rollupDeployState);
    } else {
      handleRollupConfirmClose();
      handleRollupTestnetProveOpen();
    }
  }, [
    handleRollupConfirmClose,
    handleRollupTestnetProveOpen,
    postRollupDeployment,
    rollupDeployState,
  ]);

  useEffect(() => {
    if (isPostRollupDeploymentSuccess) {
      if (
        rollupDeployState.grade === GRADE.mainnet &&
        postRollupDeploymentResult?.subscription?.url
      ) {
        handleRollupConfirmClose();
        window.location.assign(postRollupDeploymentResult.subscription.url);
      }
    }
  }, [
    handleRollupConfirmClose,
    isPostRollupDeploymentSuccess,
    postRollupDeploymentResult?.subscription?.url,
    rollupDeployState.grade,
  ]);

  return (
    <AppModal
      open={rollupConfirmOpen}
      onClose={handleRollupConfirmClose}
      title={t(keys.deployRollupConfirmModal.title)}
      subtitle={t(keys.deployRollupConfirmModal.subtitle)}
      classes={{ paper: classes.modalContent }}
    >
      <Box className={classes.listBody}>
        {currentStack && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.stack)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={currentStack.icon}
                  alt={currentStack.title}
                  className={classes.planModalImg}
                />
                {currentStack.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollupDeployState.chainId && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.chainId)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.chainId}
            </Box>
          </Box>
        )}

        {rollupDeployState.networkName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.networkName)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.networkName}
            </Box>
          </Box>
        )}

        {rollupDeployState.grade && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.grade)}
            </Box>
            <Box className={classes.listRowRight}>
              <GradeChip grade={rollupDeployState.grade} />
            </Box>
          </Box>
        )}

        {currentDAL && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.DAL)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={currentDAL.icon}
                  alt={currentDAL.title}
                  className={classes.planModalImg}
                />
                {currentDAL.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollupDeployState.sequencerAddress && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.sequencerAddress)}
            </Box>
            <Box className={classes.listRowRight}>
              {truncateWalletAddress(rollupDeployState.sequencerAddress)}
            </Box>
          </Box>
        )}

        {rollupDeployState.sequencerUrl && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.sequencerUrl)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.sequencerUrl}
            </Box>
          </Box>
        )}

        {gasTokenLogo && gasTokenName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.gasToken)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={gasTokenLogo}
                  alt={gasTokenName}
                  className={classes.planModalImg}
                />
                {gasTokenName}
              </Box>
            </Box>
          </Box>
        )}

        {currentRPCAmount && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{currentRPCAmount.title}</Box>
            <Box className={classes.listRowRight}>{currentRPCAmount.value}</Box>
          </Box>
        )}
      </Box>

      {rollupDeployState.sequencerManagementType ===
        SEQUENCER_MANAGEMENT_TYPE.product &&
        rollupDeployState.grade === GRADE.mainnet && (
          <Box mt={4}>
            <Alert severity="info" sx={{ width: '100%' }}>
              {t(keys.deployRollupConfirmModal.depositHint, {
                amount: depositAmountByGasToken,
              })}
            </Alert>
          </Box>
        )}

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        <LoadingButton
          onClick={handleConfirm}
          loading={isPostRollupDeploymentLoading}
          size="large"
          fullWidth
        >
          {rollupDeployState.grade === GRADE.mainnet && currentPlan?.price
            ? t(keys.deployRollupConfirmModal.confirmAndPay, {
                price: t('number.price', {
                  value: currentPlan.price,
                }),
              })
            : t(keys.deployRollupConfirmModal.confirm)}
        </LoadingButton>
        <Button
          onClick={handleRollupConfirmClose}
          disabled={isPostRollupDeploymentLoading}
          size="large"
          fullWidth
          variant="outlined"
        >
          {t(keys.deployRollupConfirmModal.cancel)}
        </Button>
        {rollupDeployState.grade === GRADE.mainnet && currentPlan?.price && (
          <Box mt={4} textAlign="center">
            <Typography variant="body4">
              {t(keys.deployRollupConfirmModal.poweredBy)}
              <ImageX imgUrl={stripeLogoUrl} className={classes.vAlignMiddle} />
            </Typography>
          </Box>
        )}
      </Box>
    </AppModal>
  );
}
