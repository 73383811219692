import React, { JSX, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { uid } from 'react-uid';
import { LabelWrap, QueryLoadingCentered } from '@ankr.com/raas-ui';
import { Chip } from '@ankr.com/ui';
import { Box, Card, FormHelperText, Grid, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import {
  ANKR_ETH_FOR_TESTNET_AMOUNT,
  ANKR_STAKING_DEFI_URL,
  ANKR_STAKING_URL,
  ROLLUP_TESTNET_DURATION,
} from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useGetRollupDeploysQuery } from '../../../../../Dashboard/api/getRollupDeploys';
import { useTranslation } from '../../../../../i18n';
import {
  GRADE,
  IDeployRollupFormGeneralPayload,
} from '../../../../RollupConst';
import { useGetDALsQuery } from '../../api/getDALs';
import { useGetPlansQuery } from '../../api/getPlans';
import { useGetStacksQuery } from '../../api/getStacks';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useIsRollupDeployStepPassed } from '../../hooks/useIsRollupDeployStepPassed';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';

export function DeployRollupFormGeneral(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const prevGrade = useRef<GRADE | undefined>();

  const { stack, grade, dataAvailabilityLayer } = useRollupDeployState();

  const isRollupDeployStepPassed = useIsRollupDeployStepPassed();

  const { dispatchData } = useDispatchRollupDeployData();

  const {
    data: stacks,
    isLoading: isStacksLoading,
    isSuccess: isStacksSuccess,
  } = useGetStacksQuery();

  const { handleOpen: handleRollupPlansOpen } = useDialog(DialogId.RollupPlans);

  const { control, handleSubmit, getValues, resetField } =
    useForm<IDeployRollupFormGeneralPayload>({
      defaultValues: {
        stack,
        grade,
        dataAvailabilityLayer,
      },
    });

  const { data: rollupListData } = useGetRollupDeploysQuery();

  const hasTestnet = useMemo(
    () => rollupListData?.some(rollup => rollup.plan.grade === GRADE.testnet),
    [rollupListData],
  );

  const { data: plans } = useGetPlansQuery(
    {
      grade,
      stack,
    },
    {
      skip: !isStacksSuccess || !grade || !stack,
    },
  );

  useEffect(() => {
    if (grade === GRADE.testnet && !!plans?.length) {
      dispatchData({
        ...getValues(),
        planUuid: plans[0].uuid,
      });
    }
  }, [dispatchData, getValues, grade, plans, plans?.length]);

  useEffect(() => {
    if (prevGrade.current === GRADE.testnet && grade === GRADE.mainnet) {
      dispatchData({
        ...getValues(),
        planUuid: '',
      });
    }
    prevGrade.current = grade;
  }, [dispatchData, getValues, grade]);

  const { data: DALs, isLoading: isDALsLoading } = useGetDALsQuery(
    {
      grade,
      stack,
    },
    {
      skip: !isStacksSuccess || !grade || !stack,
    },
  );

  useEffect(() => {
    resetField('dataAvailabilityLayer');
    dispatchData({
      ...getValues(),
      dataAvailabilityLayer: '',
    });
  }, [dispatchData, getValues, resetField]);

  useEffect(() => {
    if (stack && grade === GRADE.mainnet && !isRollupDeployStepPassed) {
      handleRollupPlansOpen();
    }
  }, [grade, handleRollupPlansOpen, isRollupDeployStepPassed, stack]);

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormGeneralPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  if (isStacksLoading) {
    return <QueryLoadingCentered size={40} />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.sectionTitleWrap}>
            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {t(keys.deployRollupStack.title)}
            </Typography>
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {t(keys.deployRollupStack.subtitle)}
            </Typography>
          </Box>

          <Grid container spacing={2.5}>
            <Controller
              name="stack"
              control={control}
              rules={{
                required: t('validation.required-one'),
              }}
              render={({ field, fieldState }) => (
                <>
                  {stacks?.map(stack => (
                    <Grid item key={uid(stack)} xs={12} sm={6} lg={3}>
                      <LabelWrap
                        className={classes.labelWrap}
                        active={field.value === stack.uuid}
                        component={stack.comingSoon ? 'div' : 'label'}
                      >
                        <>
                          {stack.comingSoon || (
                            <input
                              type="radio"
                              {...field}
                              value={stack.uuid}
                              hidden
                            />
                          )}
                          <Box
                            mb={7.5}
                            display="flex"
                            justifyContent="space-between"
                            gap={2}
                            width="100%"
                          >
                            <img
                              src={stack.icon}
                              alt={stack.title}
                              className={classes.itemLogo}
                            />

                            {stack.comingSoon && (
                              <Chip
                                label={t('common.coming-soon')}
                                size="small"
                                color="secondary"
                              />
                            )}
                          </Box>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            mb={3}
                            className={cx(
                              stack.comingSoon && classes.textDisabled,
                            )}
                          >
                            {stack.title}
                          </Typography>
                          <Typography
                            variant="body3"
                            component="div"
                            className={cx(
                              stack.comingSoon && classes.textDisabled,
                            )}
                          >
                            {stack.description}
                          </Typography>
                        </>
                      </LabelWrap>
                    </Grid>
                  ))}
                  {!!fieldState.error?.message && (
                    <Grid item xs={12}>
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Grid>
                  )}
                </>
              )}
            />
          </Grid>
        </Box>

        {isStacksSuccess && stack && (
          <Box className={classes.section}>
            <Box className={classes.sectionTitleWrap}>
              <Typography
                className={classes.sectionTitle}
                variant="subtitle1"
                component="div"
              >
                {t(keys.deployRollupGrade.title)}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                variant="body3"
                component="div"
              >
                {t(keys.deployRollupGrade.subtitle)}
              </Typography>
            </Box>

            <Grid container spacing={2.5}>
              <Controller
                name="grade"
                control={control}
                rules={{
                  required: t('validation.required-one'),
                }}
                render={({ field, fieldState }) => (
                  <>
                    <Grid item xs={12} sm={6}>
                      <LabelWrap
                        className={classes.labelWrap}
                        active={field.value === GRADE.testnet}
                        component={hasTestnet ? 'div' : 'label'}
                      >
                        {hasTestnet || (
                          <input
                            type="radio"
                            {...field}
                            value={GRADE.testnet}
                            hidden
                          />
                        )}
                        <Box
                          mb={3}
                          display="flex"
                          gap={2}
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box display="flex" gap={2}>
                            <Typography
                              variant="subtitle2"
                              className={cx(hasTestnet && classes.textDisabled)}
                            >
                              {t('common.testnet')}
                            </Typography>
                            <Chip
                              label={t('common.free')}
                              size="small"
                              color="secondary"
                            />
                            <Chip
                              label={t('time-unit.hour', {
                                value: ROLLUP_TESTNET_DURATION,
                              })}
                              size="small"
                              color="secondary"
                            />
                          </Box>
                          {hasTestnet && (
                            <Chip
                              label={t(keys.deployRollupGrade.alreadyInUse)}
                              size="small"
                              color="error"
                            />
                          )}
                        </Box>
                        <Typography
                          variant="body3"
                          component="div"
                          className={cx(hasTestnet && classes.textDisabled)}
                        >
                          {t(
                            keys.deployRollupGrade.testnetHint,
                            {
                              testnetDuration: ROLLUP_TESTNET_DURATION,
                              ankrEthAmount: ANKR_ETH_FOR_TESTNET_AMOUNT,
                              stakingUrl: ANKR_STAKING_URL,
                              stakingDefiUrl: ANKR_STAKING_DEFI_URL,
                            },
                            true,
                          )}
                        </Typography>
                      </LabelWrap>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelWrap
                        className={classes.labelWrap}
                        active={field.value === GRADE.mainnet}
                        onClick={handleRollupPlansOpen}
                      >
                        <input
                          type="radio"
                          {...field}
                          value={GRADE.mainnet}
                          hidden
                        />
                        <Box mb={3} display="flex" gap={2}>
                          <Typography variant="subtitle2">
                            {t('common.mainnet')}
                          </Typography>
                          <Chip
                            // TODO: min price for stack plans
                            label={t('number.price-from-month', {
                              value: 2750,
                            })}
                            size="small"
                            color="primary"
                          />
                        </Box>
                        <Typography variant="body3" component="div" mb={4}>
                          {t(keys.deployRollupGrade.mainnetHint)}
                        </Typography>
                      </LabelWrap>
                    </Grid>
                    {!!fieldState.error?.message && (
                      <Grid item xs={12}>
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Grid>
                    )}
                  </>
                )}
              />
            </Grid>
          </Box>
        )}

        {grade && (
          <Box className={classes.section}>
            <Box className={classes.sectionTitleWrap}>
              <Typography
                className={classes.sectionTitle}
                variant="subtitle1"
                component="div"
              >
                {t(keys.deployRollupDataAvailableLayer.title)}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                variant="body3"
                component="div"
              >
                {t(keys.deployRollupDataAvailableLayer.subtitle)}
              </Typography>
            </Box>
            {isDALsLoading ? (
              <QueryLoadingCentered />
            ) : (
              <Grid container spacing={2.5}>
                <Controller
                  name="dataAvailabilityLayer"
                  control={control}
                  rules={{
                    required: t('validation.required-one'),
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      {DALs?.map(DAL => (
                        <Grid item key={uid(DAL)} xs={12} sm={6} lg={3}>
                          <LabelWrap
                            className={classes.labelWrap}
                            active={field.value === DAL.uuid}
                            component={DAL.comingSoon ? 'div' : 'label'}
                          >
                            <>
                              {DAL.comingSoon || (
                                <input
                                  type="radio"
                                  {...field}
                                  value={DAL.uuid}
                                  hidden
                                />
                              )}
                              <Box
                                mb={7.5}
                                display="flex"
                                justifyContent="space-between"
                                gap={2}
                                width="100%"
                              >
                                <img
                                  src={DAL.icon}
                                  alt={DAL.title}
                                  className={classes.itemLogo}
                                />

                                {DAL.comingSoon && (
                                  <Chip
                                    label={t('common.coming-soon')}
                                    size="small"
                                    color="secondary"
                                  />
                                )}
                              </Box>
                              <Typography
                                variant="subtitle2"
                                component="div"
                                mb={3}
                                className={cx(
                                  DAL.comingSoon && classes.textDisabled,
                                )}
                              >
                                {DAL.title}
                              </Typography>
                              <Typography
                                variant="body3"
                                component="div"
                                className={cx(
                                  DAL.comingSoon && classes.textDisabled,
                                )}
                              >
                                {DAL.description}
                              </Typography>
                            </>
                          </LabelWrap>
                        </Grid>
                      ))}
                      {!!fieldState.error?.message && (
                        <Grid item xs={12}>
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        </Grid>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
          </Box>
        )}
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
