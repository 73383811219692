import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { AuthGuard } from '../guards';
import { AccountInfoGuard } from '../guards/AccountInfoGuard';
import { t } from '../i18n';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { Billing } from './screens/Billing';

const PATH = '/billing';

interface IBillingItemRouteProps {
  rollupUuid: string;
}

export const BillingRoutesConfig = createRouteConfig(
  {
    Billing: {
      path: PATH,
      generatePath: () => generatePath(PATH),
    },
    BillingItem: {
      path: PATH,
      generatePath: ({ rollupUuid }: IBillingItemRouteProps) =>
        generatePath(`${PATH}/?rollupUuid=${rollupUuid}`),
    },
  },
  PATH,
);

export function getBillingRoutes(): JSX.Element {
  return (
    <Route
      path={BillingRoutesConfig.root}
      element={
        <LayoutDefault title={t('page-title.billing')}>
          <AuthGuard>
            <AccountInfoGuard>
              <Billing />
            </AccountInfoGuard>
          </AuthGuard>
        </LayoutDefault>
      }
    />
  );
}
