import { makeStyles } from 'tss-react/mui';

export const useRollupDepositStyles = makeStyles()(theme => ({
  alertBlock: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },

  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 640,
  },

  smallFlatBtn: {
    '&&': {
      border: 0,
      backgroundColor: 'transparent',
    },
  },

  logoImg: {
    width: 20,
    height: 20,
  },

  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
