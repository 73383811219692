export const ENV = import.meta.env.VITE_ENV;

export const IS_DEV = !ENV || ENV === 'dev';

export const IS_STAGE = ENV === 'stage';

export const IS_PROD = ENV === 'prod';

const AUTH_DEV_PATH = 'https://stage-auth.ankr.com/?providers=google';
const AUTH_PROD_PATH = 'https://auth.ankr.com/?providers=google';
const AUTH_PATH = IS_PROD ? AUTH_PROD_PATH : AUTH_DEV_PATH;

const APP_AUTH_DEV_PATH = 'stage.rollup.asphere.xyz';
const APP_AUTH_PROD_PATH = 'rollup.asphere.xyz';

const APP_PATH = IS_PROD ? APP_AUTH_PROD_PATH : APP_AUTH_DEV_PATH;

export const authParams = {
  authPath: AUTH_PATH,
  appPath: APP_PATH,
};
