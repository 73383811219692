import React, { JSX } from 'react';
import { Chip, OverlaySpinner, Success, Warning } from '@ankr.com/ui';

import { useTranslation } from '../../../i18n';
import { ROLLUP_STATUS } from '../../../Rollup/RollupConst';
import { rollupStatusChipTranslation } from './translation';

interface IRollupStatusChipProps {
  status?: ROLLUP_STATUS;
}

export function RollupStatusChip({
  status,
}: IRollupStatusChipProps): JSX.Element {
  const { keys, t } = useTranslation(rollupStatusChipTranslation);

  switch (status) {
    case ROLLUP_STATUS.initializing:
      return (
        <Chip
          icon={<OverlaySpinner size={12} color="inherit" />}
          label={t(keys.status.initializing)}
          color="secondary"
        />
      );
    case ROLLUP_STATUS.pending_deposit:
      return (
        <Chip
          icon={<Warning />}
          label={t(keys.status.pendingDeposit)}
          color="error"
        />
      );
    case ROLLUP_STATUS.unpaid:
      return (
        <Chip icon={<Warning />} label={t(keys.status.unpaid)} color="error" />
      );
    case ROLLUP_STATUS.pending:
      return (
        <Chip
          icon={<OverlaySpinner size={12} color="inherit" />}
          label={t(keys.status.pending)}
          color="secondary"
        />
      );
    case ROLLUP_STATUS.deploying_bridge:
      return (
        <Chip
          icon={<OverlaySpinner size={12} color="inherit" />}
          label={t(keys.status.deployingBridge)}
          color="secondary"
        />
      );
    case ROLLUP_STATUS.failed:
      return (
        <Chip icon={<Warning />} label={t(keys.status.failed)} color="error" />
      );
    case ROLLUP_STATUS.deployed:
      return (
        <Chip
          icon={<Success color="success" />}
          label={t(keys.status.deployed)}
          color="success"
        />
      );

    default:
      return <Chip label={t('common.empty-placeholder')} color="secondary" />;
  }
}
