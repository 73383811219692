import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { uid } from 'react-uid';
import { QueryLoadingAbsolute } from '@ankr.com/raas-ui';
import { Check, Chip, CreditCard, Select } from '@ankr.com/ui';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryParam } from 'use-query-params';

import { DialogId } from '../../../common/actions/openDialog';
import { ListEmpty } from '../../../common/components/ListEmpty';
import { RollupPayModal } from '../../../common/components/RollupPayModal';
import { RollupStatusChip } from '../../../common/components/RollupStatusChip';
import { useDialog } from '../../../common/hooks/useDialog';
import { useGetRollupDeploysQuery } from '../../../Dashboard/api/getRollupDeploys';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';
import { GRADE, ROLLUP_STATUS } from '../../../Rollup/RollupConst';
import { billingTranslation } from '../../translation';
import { BillingHistory } from './components/BillingHistory';
import { useBillingStyles } from './useBillingStyles';

export function Billing(): JSX.Element {
  const { classes } = useBillingStyles();

  const { keys, t } = useTranslation(billingTranslation);

  const breadcrumbs = useLocaleMemo(
    () => [
      {
        title: t('navigation.billing'),
      },
    ],
    [t],
  );
  useSetBreadcrumbs(breadcrumbs);

  const { handleOpen: handleRollupPayOpen } = useDialog(DialogId.RollupPay);

  const {
    data: rollupListData,
    isLoading: isRollupListLoading,
    isSuccess: isRollupListSuccess,
    isError: isRollupListError,
  } = useGetRollupDeploysQuery();

  const [rollupUuid, setRollupUuid] = useQueryParam<string | undefined>(
    'rollupUuid',
  );

  const mainnetRollupList = useMemo(
    () =>
      rollupListData?.filter(rollup => rollup?.plan?.grade === GRADE.mainnet),
    [rollupListData],
  );

  const isMainnetRollupListEmpty = useMemo(
    () =>
      (isRollupListSuccess || isRollupListError) &&
      (!mainnetRollupList || !mainnetRollupList?.length),
    [isRollupListError, isRollupListSuccess, mainnetRollupList],
  );

  useEffect(() => {
    if (!rollupUuid && !!mainnetRollupList?.length) {
      setRollupUuid(mainnetRollupList[0].uuid);
    }
  }, [mainnetRollupList, rollupUuid, setRollupUuid]);

  const rollupData = useMemo(
    () => rollupListData?.find(rollup => rollup.uuid === rollupUuid),
    [rollupListData, rollupUuid],
  );

  const handleChange = (event: SelectChangeEvent<string>): void => {
    setRollupUuid(event.target.value);
  };

  const renderValue = useCallback(
    (value: string) => {
      const rollup = mainnetRollupList?.find(
        rollupFromList => rollupFromList.uuid === value,
      );
      return (
        <Box display="flex" gap={2} alignItems="center">
          {rollup?.plan.stack.icon && rollup?.networkName && (
            <img
              src={rollup?.plan.stack.icon}
              alt={rollup?.networkName}
              className={classes.stackLogo}
            />
          )}
          <Typography variant="body3" component={Box}>
            {rollup?.networkName}
          </Typography>
        </Box>
      );
    },
    [classes.stackLogo, mainnetRollupList],
  );

  const dropdownOptions = useMemo(
    () =>
      mainnetRollupList?.map(rollup => (
        <MenuItem key={uid(rollup)} value={rollup.uuid}>
          {renderValue(rollup.uuid)}
        </MenuItem>
      )),
    [mainnetRollupList, renderValue],
  );

  if (isRollupListLoading) {
    return <QueryLoadingAbsolute />;
  }

  return (
    <Container>
      {isMainnetRollupListEmpty ? (
        <ListEmpty title={t(keys.emptyTitle)} />
      ) : (
        <>
          <Grid container spacing={5} mb={17}>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Box
                  display="flex"
                  gap={2}
                  flexDirection="column"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Box
                      display="flex"
                      gap={3}
                      justifyContent="flex-start"
                      flexWrap="wrap"
                    >
                      <Typography variant="subtitle1">
                        {t(keys.plan.title)}
                      </Typography>
                      {rollupData?.plan.title && (
                        <Chip
                          label={rollupData?.plan.title}
                          color="primary"
                          size="medium"
                        />
                      )}
                      <RollupStatusChip status={rollupData?.status} />
                    </Box>

                    {!!rollupUuid &&
                      !!dropdownOptions &&
                      !!dropdownOptions.length && (
                        <Select
                          value={rollupUuid}
                          onChange={handleChange}
                          renderValue={renderValue}
                          size="small"
                          variant="filled"
                        >
                          {dropdownOptions}
                        </Select>
                      )}
                  </Box>

                  <Box
                    display="flex"
                    gap={4}
                    flexDirection="column"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Box display="flex" gap={2} justifyContent="space-between">
                      {rollupData?.plan.price &&
                        !rollupData?.plan.price.isZero() && (
                          <Typography
                            variant="body3"
                            component="div"
                            className={classes.value}
                          >
                            {t(
                              'number.price-per-month',
                              { value: rollupData.plan.price },
                              true,
                            )}
                          </Typography>
                        )}

                      {rollupData?.status === ROLLUP_STATUS.unpaid && (
                        <>
                          <Button
                            onClick={handleRollupPayOpen}
                            sx={{ placeSelf: 'flex-end' }}
                          >
                            {t(keys.plan.pay, {
                              price: t('number.price', {
                                value: rollupData?.plan.price,
                              }),
                            })}
                          </Button>
                          <RollupPayModal rollup={rollupData} />
                        </>
                      )}
                    </Box>

                    {rollupData?.paidUntil && (
                      <Typography
                        variant="body3"
                        component="div"
                        className={classes.value}
                        display="flex"
                        alignItems="center"
                        gap={2}
                      >
                        <CreditCard color="primary" />
                        {t(keys.plan.nextPayment, {
                          value: t('datetime.date-medium', {
                            value: rollupData.paidUntil,
                          }),
                        })}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Typography variant="subtitle1">
                  {t(keys.includes.title)}
                </Typography>
                {!!rollupData?.plan.settings?.length && (
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    {rollupData?.plan.settings.map(settingItem => {
                      const titleString =
                        typeof settingItem.value === 'boolean'
                          ? settingItem.title
                          : t('common.two-spaced', {
                              first: settingItem.value,
                              second: settingItem.title,
                            });

                      return (
                        <Typography
                          variant="body3"
                          key={uid(settingItem)}
                          component="div"
                          display="flex"
                          alignItems="center"
                          gap={2}
                          className={classes.value}
                        >
                          <Check color="success" />
                          {titleString}
                        </Typography>
                      );
                    })}
                  </Stack>
                )}
              </Card>
            </Grid>
          </Grid>
          <BillingHistory rollupUuid={rollupUuid} />
        </>
      )}
    </Container>
  );
}
