import { Locale } from '../../../i18n';

export const expiredTranslation = {
  [Locale.en]: {
    expired: {
      until: 'Until {date}',
      expired: 'Expired',
    },
  },
};
