import React, { JSX } from 'react';
import { useAuth } from '@ankr.com/auth';
import { useScrollToTop } from '@ankr.com/raas-hooks';
import { Notifications, QueryLoadingAbsolute } from '@ankr.com/raas-ui';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { authParams } from '../../../common/const/environment';
import { useLocaleInit } from '../../../i18n/hooks/useLocaleInit';
import { mainTheme } from '../../../themes/mainTheme';

interface IAppBaseProps {
  children: JSX.Element;
}

export const muiCache = createCache({
  key: 'mui',
});

export function AppBase({ children }: IAppBaseProps): JSX.Element {
  useScrollToTop();

  const { isLocaleReady } = useLocaleInit();

  useAuth(authParams);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {isLocaleReady ? children : <QueryLoadingAbsolute />}
        <Notifications />
      </ThemeProvider>
    </CacheProvider>
  );
}
