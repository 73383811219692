import { makeStyles } from 'tss-react/mui';

export const useDeployRollupStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(20),
  },
  card: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },

  formHeader: {
    marginBottom: theme.spacing(10),
  },

  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  formControlWrap: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  amountWrap: {
    display: 'flex',
    gap: theme.spacing(8),
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2.5),
    marginBottom: theme.spacing(5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formControlPanel: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlBtn: {
    minWidth: 112,
  },

  vAlignMiddle: {
    verticalAlign: 'middle',
  },
  textDisabled: {
    color: theme.palette.text.secondary,
  },

  section: {
    marginBottom: theme.spacing(10),
  },
  sectionTitleWrap: {
    width: '50%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 456,
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  sectionSubtitle: {
    color: theme.palette.text.secondary,
  },

  sectionHalfWrap: {
    width: '50%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  alertBlock: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },

  labelWrap: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelContentStretch: {
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'stretch',
    justifyContent: 'space-between',
    gap: theme.spacing(12),
  },

  inputLabelWrap: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  questionIcon: {
    width: 20,
    height: 20,
    color: theme.palette.text.secondary,
  },

  radioGroup: {
    gap: theme.spacing(5),
  },

  itemLogo: {
    width: 40,
    height: 40,
  },

  gasTokenIcon: {
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    marginRight: theme.spacing(1.5),
  },

  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 600,
  },

  plansModalContent: {
    padding: theme.spacing(5),
    maxWidth: 715,
  },
  plansModalTitle: {
    fontSize: 35,
  },

  planModalImg: {
    width: 20,
    height: 20,
  },

  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
