import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, mainApi } from '../../../../api';
import { Gateways } from '../../../../apiService';
import { IAccountInfo, IAccountInfoFormApi } from './types';

export const { useGetAccountInfoQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getAccountInfo: build.query<IAccountInfo, void>({
      queryFn: queryFnNotifyWrapper<void, never, IAccountInfo>(async () => {
        const { data }: { data: IAccountInfoFormApi } =
          await Gateways.auth.api.get('/api/v1/user/details');

        const mappedData: IAccountInfo = {
          email: data.email,
          companyName: data.company_name,
          companyInfo: data.company_info,
          companyTwitter: data.company_twitter,
          isVerified: data.is_verified,
        };

        return { data: mappedData };
      }),
      providesTags: [cacheTags.accountInfo],
    }),
  }),
});
