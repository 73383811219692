import { Hours } from '@ankr.com/raas-types';

import { IS_PROD } from './environment';

export const ANKR_STAKING_URL = 'https://www.ankr.com/staking/dashboard/';
export const ANKR_STAKING_DEFI_URL =
  'https://www.ankr.com/staking/defi/?assets=ankrETH';
export const ANKR_STAKING_DOCS_URL =
  'https://www.ankr.com/docs/staking-overview/';

export const CUSTOM_GAS_TOKEN_DOCS_URL = '';

export const SELF_SEQUENCER_DOCS_URL = '';

const ROLLUP_DEPLOYMENT_DEV_DOCS_URL =
  'https://bs-3-stage.dccn.ankr.com/docs/scaling-services-nocode-deployer/';
const ROLLUP_DEPLOYMENT_PROD_DOCS_URL =
  'https://www.ankr.com/docs/scaling-services-nocode-deployer/';
export const ROLLUP_DEPLOYMENT_DOCS_URL = IS_PROD
  ? ROLLUP_DEPLOYMENT_PROD_DOCS_URL
  : ROLLUP_DEPLOYMENT_DEV_DOCS_URL;

export const ROLLUP_DEPLOYMENT_BRIDGE_DOCS_URL = `${ROLLUP_DEPLOYMENT_DOCS_URL}#arbitrum-bridge-setup`;

export const ROLLUP_TESTNET_DURATION: Hours = 48;

export const SEQUENCER_FEES_PERCENT = 6.5;

export const ANKR_ETH_FOR_TESTNET_AMOUNT = 1;

export const METAMASK_INSTALL_URL = 'https://metamask.io/download/';
