import { IRollupDeployed, IRollupDeployedApi } from '../../Rollup/RollupConst';
import { mapPlanFromApi } from '../../Rollup/screens/DeployRollup/utils/mapPlansFromApi';
import { mapDepositsForDeploymentFromApi } from './mapDepositsFromApi';

const getRpcEndpoints = (rpcFromApi: string | string[] | undefined) => {
  if (!rpcFromApi) {
    return undefined;
  }
  return Array.isArray(rpcFromApi) ? rpcFromApi : [rpcFromApi];
};

export function mapDeploymentFromApi({
  deployment,
}: {
  deployment: IRollupDeployedApi;
}): IRollupDeployed {
  return {
    createdAt: new Date(deployment.created_at),
    paidUntil: deployment.paid_until
      ? new Date(deployment.paid_until)
      : undefined,
    uuid: deployment.uuid,
    plan: mapPlanFromApi(deployment.plan),
    deposits: mapDepositsForDeploymentFromApi(deployment.deposits),
    optionalSettings: deployment.optional_settings,
    status: deployment.status,
    sequencerManagementType: deployment.sequencer_management_type,
    sequencerAddress: deployment.sequencer_address,
    sequencerUrl: deployment.sequencer_url,
    chainId: deployment.chain_id,
    networkName: deployment.network_name,
    gasToken: deployment.gas_token,
    gasTokenAddress: deployment.gas_token_address,
    l1Chain: deployment.l1_chain,
    dataAvailabilityLayer: deployment.data_availability_layer,
    extendable: deployment.extendable,
    details: deployment.details
      ? {
          blockHeight: deployment.details.block_height,
          rpcEndpoints: getRpcEndpoints(deployment.details.rpc_url),
          bridgeUrl: deployment.details.bridge_url,
          explorerUrl: deployment.details.explorer_url,
        }
      : undefined,
  };
}
