import React, { JSX, useEffect, useMemo, useRef } from 'react';
import { uid } from 'react-uid';
import { useIsInPast } from '@ankr.com/raas-hooks';
import { Days, Milliseconds } from '@ankr.com/raas-types';
import {
  AppModal,
  CopyButton,
  ImageX,
  QueryEmpty,
  QueryLoadingAbsolute,
} from '@ankr.com/raas-ui';
import { addDays } from '@ankr.com/raas-utils';
import { Check, Chip, Close, ExternalLink, LoadingButton } from '@ankr.com/ui';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useQueryParam } from 'use-query-params';

import { DialogId } from '../../../common/actions/openDialog';
import imgUrl from '../../../common/assets/success.png';
import imgUrl2x from '../../../common/assets/success@2x.png';
import { CountDownString } from '../../../common/components/CountDownString';
import { ExpiredDateChip } from '../../../common/components/ExpiredDateChip';
import { GradeChip } from '../../../common/components/GradeChip';
import { PaymentStatusModals } from '../../../common/components/PaymentStatusModals';
import { RollupPayModal } from '../../../common/components/RollupPayModal';
import { RollupStatusChip } from '../../../common/components/RollupStatusChip';
import { ROLLUP_TESTNET_DURATION } from '../../../common/const/values';
import { useDialog } from '../../../common/hooks/useDialog';
import { getToken } from '../../../common/utils/getToken';
import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';
import {
  GRADE,
  ROLLUP_STATUS,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../RollupConst';
import { useExtendRollupMutation } from './api/extendRollup';
import { useGetRollupQuery } from './api/getRollup';
import { RollupDepositModal } from './components/RollupDepositModal';
import { RollupRPCEndpointsModal } from './components/RollupRPCEndpointsModal';
import { rollupTranslation } from './translation';
import { useRollupStyles } from './useRollupStyles';

const POLLING_INTERVAL: Milliseconds = 5000;
const CUTOFF_EXPIRED_DAYS: Days = -1;

export function Rollup(): JSX.Element {
  const { classes, cx } = useRollupStyles();

  const { keys, t } = useTranslation(rollupTranslation);

  const [uuid] = useQueryParam<string>('uuid');

  const rollupStatusRef = useRef<ROLLUP_STATUS | undefined>();

  const {
    data: rollupData,
    isLoading: isRollupLoading,
    isSuccess: isRollupSuccess,
    isError: isRollupError,
  } = useGetRollupQuery(
    { uuid },
    {
      pollingInterval:
        rollupStatusRef.current === ROLLUP_STATUS.initializing ||
        rollupStatusRef.current === ROLLUP_STATUS.pending_deposit ||
        rollupStatusRef.current === ROLLUP_STATUS.pending ||
        rollupStatusRef.current === ROLLUP_STATUS.deploying_bridge ||
        rollupStatusRef.current === ROLLUP_STATUS.deployed
          ? POLLING_INTERVAL
          : 0,
      skip: !uuid,
    },
  );

  useEffect(() => {
    if (
      !!rollupData?.status &&
      rollupStatusRef.current !== rollupData?.status
    ) {
      rollupStatusRef.current = rollupData?.status;
    }
  }, [rollupData?.status]);

  const breadcrumbs = useLocaleMemo(
    () => [
      {
        title: t('navigation.dashboard'),
        link: DashboardRoutesConfig.Dashboard.generatePath(),
      },
      {
        title: rollupData?.networkName ?? t('common.empty-placeholder'),
      },
    ],
    [rollupData?.networkName, t],
  );
  useSetBreadcrumbs(breadcrumbs);

  const { handleOpen: handleRollupDepositModalOpen } = useDialog(
    DialogId.RollupDeposit,
  );

  const { handleOpen: handleRollupPayOpen } = useDialog(DialogId.RollupPay);

  const isRollupEmpty = useMemo(
    () => (isRollupSuccess || isRollupError) && !rollupData,
    [isRollupError, isRollupSuccess, rollupData],
  );

  const isExpired = useIsInPast({ endDate: rollupData?.paidUntil });

  const isExtendable = useIsInPast({
    endDate: addDays({
      date: rollupData?.paidUntil,
      days: CUTOFF_EXPIRED_DAYS,
    }),
  });

  const [
    extendRollup,
    { isLoading: isExtendRollupLoading, isSuccess: isExtendRollupSuccess },
  ] = useExtendRollupMutation();

  const {
    open: extendRollupModalOpen,
    handleClose: handleExtendRollupTestnetModalClose,
    handleOpen: handleExtendRollupModalOpen,
  } = useDialog(DialogId.ExtendRollupTestnet);

  const { handleOpen: handleRPCEndpointsModalOpen } = useDialog(
    DialogId.RollupRPCEndpoints,
  );

  useEffect(() => {
    if (isExtendRollupSuccess) {
      handleExtendRollupModalOpen();
    }
  }, [handleExtendRollupModalOpen, isExtendRollupSuccess]);

  const { imageSrc: gasTokenLogo, name: gasTokenName } = getToken({
    value: rollupData?.gasToken,
  });

  const { imageSrc: l1ChainTokenLogo, name: l1ChainTokenName } = getToken({
    value: rollupData?.l1Chain,
  });

  const RPCAmount = useMemo(() => {
    return rollupData?.plan?.settings?.find(
      setting => setting.key === 'rpc_amount',
    )?.value;
  }, [rollupData?.plan?.settings]);

  if (isRollupEmpty) {
    return (
      <Container className={classes.root}>
        <QueryEmpty />
      </Container>
    );
  }

  return (
    <>
      <Container className={classes.root}>
        {isRollupLoading ? (
          <QueryLoadingAbsolute />
        ) : (
          <Box className={classes.contentWrap}>
            <Box className={classes.titleWrap}>
              <Box display="flex" gap={2.5} alignItems="center" flexWrap="wrap">
                {rollupData?.plan.stack.icon &&
                  rollupData?.plan.stack.title && (
                    <img
                      src={rollupData?.plan.stack.icon}
                      alt={rollupData?.plan.stack.title}
                      className={classes.stackLogo}
                    />
                  )}
                <Typography variant="subtitle1" component={Box}>
                  {rollupData?.networkName}
                </Typography>
                <GradeChip
                  grade={rollupData?.plan.grade}
                  size="medium"
                  forceColor="primary"
                />
                {rollupData?.plan.grade === GRADE.mainnet && (
                  <Chip
                    label={rollupData?.plan.title}
                    color="primary"
                    size="medium"
                  />
                )}
                <RollupStatusChip status={rollupData?.status} />
              </Box>
              <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
                {rollupData?.paidUntil && (
                  <ExpiredDateChip
                    date={rollupData?.paidUntil}
                    size="medium"
                    forceString={
                      rollupData?.plan.grade === GRADE.mainnet
                        ? keys.rollup.nextPayment
                        : undefined
                    }
                  />
                )}

                {rollupData?.plan.grade === GRADE.mainnet &&
                  rollupData?.status === ROLLUP_STATUS.unpaid && (
                    <Button size="small" onClick={handleRollupPayOpen}>
                      {t(keys.rollup.pay, {
                        price: t('number.price', {
                          value: rollupData?.plan.price,
                        }),
                      })}
                    </Button>
                  )}

                {rollupData?.plan.grade === GRADE.testnet &&
                  rollupData?.extendable &&
                  !isExpired &&
                  isExtendable && (
                    <LoadingButton
                      size="small"
                      onClick={() => extendRollup({ uuid: rollupData?.uuid })}
                      loading={isExtendRollupLoading}
                    >
                      {t(keys.rollup.extend, {
                        duration: t('unit.hour-short', {
                          value: ROLLUP_TESTNET_DURATION,
                        }),
                      })}
                    </LoadingButton>
                  )}
              </Box>
            </Box>

            {rollupData?.status === ROLLUP_STATUS.pending_deposit && (
              <Alert
                severity="error"
                className={classes.depositAlert}
                action={
                  <Button
                    variant="text"
                    color="primary"
                    size="extraSmall"
                    className={classes.depositButton}
                    onClick={handleRollupDepositModalOpen}
                  >
                    {t(keys.rollup.depositButton, {
                      amount:
                        rollupData?.deposits?.parsedForUI.amountsWithToken?.join(
                          t('common.plus-sign-spaced'),
                        ),
                    })}
                  </Button>
                }
              >
                {t(keys.rollup.pendingDepositAlert, {
                  amount:
                    rollupData?.deposits?.parsedForUI.amountsWithToken?.join(
                      t('common.plus-sign-spaced'),
                    ),
                })}
              </Alert>
            )}

            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box display="flex" flexGrow={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      flexGrow={1}
                      justifyContent="space-between"
                    >
                      <Box className={classes.listRow} mb={9}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button1"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.RPCNodes)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight} />
                      </Box>

                      {!!RPCAmount && (
                        <Box>
                          <Typography variant="h5">
                            {t(keys.rollup.nodesAmount, {
                              number: RPCAmount,
                            })}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.generalInfo)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.listBody}>
                    <Box className={classes.listRow}>
                      <Box className={classes.listRowLeft}>
                        <Typography
                          variant="button3"
                          className={classes.infoTitle}
                        >
                          {t(keys.rollup.chainId)}
                        </Typography>
                      </Box>
                      <Box className={classes.listRowRight}>
                        <Typography variant="button3">
                          {rollupData?.chainId}
                        </Typography>
                      </Box>
                    </Box>

                    {!!l1ChainTokenLogo && !!l1ChainTokenName && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.l1Chain)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            <Box display="flex" gap={2} alignItems="center">
                              <img
                                src={l1ChainTokenLogo}
                                alt={l1ChainTokenName}
                                className={classes.blockchainLogo}
                              />
                              {l1ChainTokenName}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {!isExpired && rollupData?.paidUntil && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.duration)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography
                            variant="button3"
                            sx={{ fontFeatureSettings: '"tnum" 1' }}
                          >
                            <CountDownString date={rollupData.paidUntil} />
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box className={classes.listRow}>
                      <Box className={classes.listRowLeft}>
                        <Typography
                          variant="button3"
                          className={classes.infoTitle}
                        >
                          {t(keys.rollup.creationDate)}
                        </Typography>
                      </Box>
                      <Box className={classes.listRowRight}>
                        <Typography variant="button3">
                          {t('datetime.medium-short', {
                            value: rollupData?.createdAt,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} lg={4} display="flex">
                <Grid container columnSpacing={2} rowSpacing={2.5} flexGrow={1}>
                  <Grid item xs={12} md={6} lg={12}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.blockHeight &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.blockHeight)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            {rollupData?.details?.blockHeight ||
                              t('common.empty-placeholder')}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6} lg={12}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.rpcEndpoints &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                            whiteSpace="nowrap"
                            component={Box}
                          >
                            {t(
                              !!rollupData?.details?.rpcEndpoints &&
                                rollupData.details.rpcEndpoints.length > 1
                                ? keys.rollup.RPCEndpoints
                                : keys.rollup.RPCEndpoint,
                            )}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight} overflow="hidden">
                          {!rollupData?.details?.rpcEndpoints && (
                            <Typography variant="button3">
                              {t('common.empty-placeholder')}
                            </Typography>
                          )}

                          {!!rollupData?.details?.rpcEndpoints &&
                            rollupData.details.rpcEndpoints.length === 1 && (
                              <Box display="flex" gap={1} alignItems="center">
                                <Typography
                                  variant="button3"
                                  component={Box}
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                >
                                  {rollupData.details.rpcEndpoints[0]}
                                </Typography>
                                <CopyButton
                                  text={rollupData.details.rpcEndpoints[0]}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            )}

                          {!!rollupData?.details?.rpcEndpoints &&
                            rollupData.details.rpcEndpoints.length > 1 && (
                              <Box display="flex" gap={1} alignItems="center">
                                <Button
                                  size="extraSmall"
                                  color="primary"
                                  variant="outlined"
                                  onClick={handleRPCEndpointsModalOpen}
                                  className={classes.smallFlatBtn}
                                >
                                  {t(keys.rollup.showAll)}
                                </Button>
                                <RollupRPCEndpointsModal
                                  RPCEndpoints={rollupData.details.rpcEndpoints}
                                />
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                  {rollupData?.sequencerManagementType ===
                  SEQUENCER_MANAGEMENT_TYPE.self ? (
                    <>
                      {!!rollupData?.sequencerUrl && (
                        <Grid item xs={6}>
                          <Card className={classes.cardSmall}>
                            <Box className={classes.listRow}>
                              <Box className={classes.listRowLeft}>
                                <Typography
                                  variant="subtitle3"
                                  className={classes.infoTitle}
                                >
                                  {t(keys.rollup.sequencerURL)}
                                </Typography>
                              </Box>
                              <Box className={classes.listRowRight}>
                                <CopyButton
                                  text={rollupData.sequencerUrl}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      )}
                      {!!rollupData?.sequencerAddress && (
                        <Grid item xs={6}>
                          <Card className={classes.cardSmall}>
                            <Box className={classes.listRow}>
                              <Box className={classes.listRowLeft}>
                                <Typography
                                  variant="subtitle3"
                                  className={classes.infoTitle}
                                >
                                  {t(keys.rollup.sequencerAddress)}
                                </Typography>
                              </Box>
                              <Box className={classes.listRowRight}>
                                <CopyButton
                                  text={rollupData.sequencerAddress}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Card className={classes.cardSmall}>
                        <Box className={classes.listRow}>
                          <Box className={classes.listRowLeft}>
                            <Typography
                              variant="subtitle3"
                              className={classes.infoTitle}
                            >
                              {t(keys.rollup.sequencer)}
                            </Typography>
                          </Box>
                          <Box className={classes.listRowRight}>
                            <Typography variant="button3">
                              {t(keys.rollup.ankrManages)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.bridgeUrl && classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.bridge)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <IconButton
                            size="extraSmall"
                            color="primary"
                            disabled={!rollupData?.details?.bridgeUrl}
                            href={rollupData?.details?.bridgeUrl || ''}
                            className={classes.smallFlatBtn}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <ExternalLink sx={{ color: 'inherit' }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={6}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.explorerUrl &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.explorer)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <IconButton
                            size="extraSmall"
                            color="primary"
                            disabled={!rollupData?.details?.explorerUrl}
                            href={rollupData?.details?.explorerUrl || ''}
                            className={classes.smallFlatBtn}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <ExternalLink sx={{ color: 'inherit' }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={8}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.package)}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid
                    container
                    columnSpacing={5}
                    className={classes.packageList}
                  >
                    {rollupData?.plan?.settings?.map(setting => {
                      const value =
                        typeof setting.value === 'boolean' ? (
                          <Check color="success" />
                        ) : (
                          setting.value
                        );

                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.packageItem}
                          key={uid(setting)}
                        >
                          <Box className={classes.listRow} key={uid(setting)}>
                            <Box className={classes.listRowLeft}>
                              <Typography
                                variant="button3"
                                className={classes.infoTitle}
                              >
                                {setting.title}
                              </Typography>
                            </Box>
                            <Box className={classes.listRowRight}>
                              <Typography
                                variant="button3"
                                minWidth={24}
                                textAlign="center"
                                component={Box}
                              >
                                {value}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.details)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.listBody}>
                    {!!rollupData?.dataAvailabilityLayer?.icon &&
                      !!rollupData?.dataAvailabilityLayer?.title && (
                        <Box className={classes.listRow}>
                          <Box className={classes.listRowLeft}>
                            <Typography
                              variant="button3"
                              className={classes.infoTitle}
                            >
                              {t(keys.rollup.DAL)}
                            </Typography>
                          </Box>
                          <Box className={classes.listRowRight}>
                            <Typography variant="button3">
                              <Box display="flex" gap={2} alignItems="center">
                                <img
                                  src={rollupData.dataAvailabilityLayer.icon}
                                  alt={rollupData.dataAvailabilityLayer.title}
                                  className={classes.blockchainLogo}
                                />
                                {rollupData.dataAvailabilityLayer.title}
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    {!!gasTokenLogo && !!gasTokenName && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.gasToken)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            <Box display="flex" gap={2} alignItems="center">
                              <img
                                src={gasTokenLogo}
                                alt={gasTokenName}
                                className={classes.blockchainLogo}
                              />
                              {gasTokenName}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
      {rollupData?.plan.grade === GRADE.testnet && (
        <AppModal
          open={extendRollupModalOpen}
          onClose={handleExtendRollupTestnetModalClose}
          maxWidth="xs"
          withoutCloseButton
          classes={{ paper: classes.successModal }}
        >
          <Box className={classes.successModalContent}>
            <IconButton
              aria-label="close"
              onClick={handleExtendRollupTestnetModalClose}
              color="inherit"
              className={classes.successModalCloseBtn}
            >
              <Close />
            </IconButton>

            <ImageX
              imgUrl={imgUrl}
              img2xUrl={imgUrl2x}
              className={classes.successModalImage}
            />

            <Typography variant="h5">
              {t(keys.extendRollupModal.title)}
            </Typography>
            <Typography variant="body3" className={classes.successModalHint}>
              {t(keys.extendRollupModal.hint, {
                duration: ROLLUP_TESTNET_DURATION,
              })}
            </Typography>

            <Button
              onClick={handleExtendRollupTestnetModalClose}
              size="large"
              fullWidth
              className={classes.successModalBtn}
            >
              {t(keys.extendRollupModal.close)}
            </Button>
          </Box>
        </AppModal>
      )}
      <PaymentStatusModals
        planPrice={rollupData?.plan?.price}
        uuid={rollupData?.uuid}
        status={rollupData?.status}
        grade={rollupData?.plan.grade}
        isProductSequencer={
          rollupData?.sequencerManagementType ===
          SEQUENCER_MANAGEMENT_TYPE.product
        }
        deposits={rollupData?.deposits}
      />
      {rollupData?.status === ROLLUP_STATUS.pending_deposit && (
        <RollupDepositModal
          deposits={rollupData?.deposits}
          l1ChainTokenLogo={l1ChainTokenLogo}
          l1ChainTokenName={l1ChainTokenName}
        />
      )}

      {rollupData?.plan.grade === GRADE.mainnet &&
        rollupData?.status === ROLLUP_STATUS.unpaid && (
          <RollupPayModal rollup={rollupData} />
        )}
    </>
  );
}
