import { t } from '@ankr.com/raas-utils';
import BigNumber from 'bignumber.js';
import { utils } from 'web3';

import {
  IDepositItem,
  IDepositItemApi,
  IDepositsForPlan,
} from '../../Rollup/RollupConst';
import { getToken } from './getToken';

export function mapDepositFromApi(
  deposit: IDepositItemApi | undefined | null,
): IDepositItem | undefined {
  if (!deposit) {
    return undefined;
  }

  const fundRequests = deposit.fund_requests.map(fundRequest => ({
    address: fundRequest.address,
    amount: new BigNumber(utils.fromWei(fundRequest.amount, 'ether')),
    token: getToken({ value: fundRequest.token }).name,
  }));

  // Creating additional object for UI: { amountsWithToken: ['1 ETH', '2 Custom Gas Token'], address: '0x000' }
  const parsedForUI = {
    amountsWithToken: fundRequests.map(fundRequest => {
      return t('common.two-spaced', {
        first: fundRequest.amount,
        second: fundRequest.token,
      });
    }),
    address: fundRequests[0].address,
  };

  return {
    fundRequests,
    isCustom: deposit.is_custom,
    parsedForUI,
  };
}

export function mapDepositsForDeploymentFromApi(
  deposits: IDepositItemApi[] | undefined | null,
): IDepositItem | undefined {
  if (!deposits) {
    return undefined;
  }

  const deposit = Array.isArray(deposits) ? deposits[0] : deposits;

  return mapDepositFromApi(deposit);
}

export function mapDepositsForPlanFromApi(
  deposits: IDepositItemApi[] | undefined | null,
): IDepositsForPlan | undefined {
  if (!deposits) {
    return undefined;
  }

  const defaultDeposits = deposits?.find(
    customDeposit => !customDeposit.is_custom,
  );
  const customDeposits = deposits?.find(
    customDeposit => customDeposit.is_custom,
  );

  return {
    default: mapDepositFromApi(defaultDeposits),
    custom: mapDepositFromApi(customDeposits),
  };
}
