import React, { useMemo } from 'react';
import { CrossNavigation, LogoType } from '@ankr.com/cross-navigation';
import { useIsMDDown, useThemeMode } from '@ankr.com/raas-themes';
import { ToggleIcon } from '@ankr.com/raas-ui';
import { Box, Container, Drawer, useTheme } from '@mui/material';

import '@ankr.com/cross-navigation/style.css';

import { AuthButton } from '../../../common/components/AuthButton';
import { Breadcrumbs } from '../Breadcrumbs';
import { MainMenu } from '../MainMenu';
import { useHeader } from './useHeader';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { classes, cx } = useHeaderStyles();

  const themeMode = useThemeMode();

  const { mobileNavShowed, toggleNav } = useHeader();

  const isBreakpointDown = useIsMDDown();

  const {
    transitions: {
      duration: { standard: transitionDuration },
    },
  } = useTheme();

  const renderMobile = useMemo(() => {
    return (
      <Box className={classes.mobileMenu}>
        <Drawer
          anchor="left"
          open={mobileNavShowed}
          onClose={toggleNav(false)}
          className={classes.drawer}
          transitionDuration={transitionDuration}
          ModalProps={{
            slotProps: {
              backdrop: {
                classes: {
                  root: classes.drawerBackdrop,
                },
              },
            },
          }}
          classes={{
            paperAnchorLeft: classes.drawerPaper,
          }}
        >
          <Box className={classes.mobileMenuWrap}>
            <Box className={cx(classes.logo, classes.mobileLogoWrap)}>
              <CrossNavigation theme={themeMode} logoType={LogoType.SCALING} />
              <ToggleIcon
                onClick={mobileNavShowed ? toggleNav(false) : toggleNav(true)}
                isActive={mobileNavShowed}
              />
            </Box>
            <Box className={classes.mobileMenuContent}>
              <MainMenu />
            </Box>
          </Box>
        </Drawer>
      </Box>
    );
  }, [
    classes.mobileMenu,
    classes.drawer,
    classes.drawerBackdrop,
    classes.drawerPaper,
    classes.mobileMenuWrap,
    classes.logo,
    classes.mobileLogoWrap,
    classes.mobileMenuContent,
    mobileNavShowed,
    toggleNav,
    transitionDuration,
    cx,
    themeMode,
  ]);

  return (
    <>
      <Box component="header" className={classes.appBar}>
        <Container className={classes.container}>
          <Box className={classes.logoWrap}>
            <CrossNavigation theme={themeMode} logoType={LogoType.SCALING} />
          </Box>

          <Box className={classes.toolbar}>
            {!isBreakpointDown && <Breadcrumbs />}
            <Box className={classes.toolbarContent}>
              <AuthButton />
              {isBreakpointDown && (
                <ToggleIcon
                  onClick={mobileNavShowed ? toggleNav(false) : toggleNav(true)}
                  isActive={mobileNavShowed}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Box>

      {isBreakpointDown && renderMobile}

      {isBreakpointDown && (
        <Box className={classes.headerBreadcrumbs}>
          <Container>
            <Breadcrumbs />
          </Container>
        </Box>
      )}
    </>
  );
}
