import React, { JSX } from 'react';
import { useAuth } from '@ankr.com/auth';
import { Box, Card, Container, Typography } from '@mui/material';

import { AuthButton } from '../../common/components/AuthButton';
import Logo from '../../common/components/icons/logo.svg?react';
import { authParams } from '../../common/const/environment';
import { useTranslation } from '../../i18n';
import { authGuardTranslation } from './translation';
import { useAuthGuardStyles } from './useAuthGuardStyles';

interface AuthGuardProps {
  children: JSX.Element;
}

export function AuthGuard({ children }: AuthGuardProps): JSX.Element {
  const { classes } = useAuthGuardStyles();

  const { keys, t } = useTranslation(authGuardTranslation);

  const { isSignedIn } = useAuth(authParams);

  return isSignedIn ? (
    children
  ) : (
    <Container>
      <Card className={classes.root}>
        <Logo className={classes.image} />
        <Typography variant="h6" component={Box} className={classes.title}>
          {t(keys.title, {}, true)}
        </Typography>

        <Box className={classes.buttonsWrap}>
          <AuthButton className={classes.button} buttonSize="large" />
        </Box>
      </Card>
    </Container>
  );
}
