import { Locale } from '../../../i18n';

export const rollupTranslation = {
  [Locale.en]: {
    rollup: {
      extend: 'Extend for {duration}',
      pay: 'Pay {price}',
      nextPayment: 'Next Payment {date}',
      RPCNodes: 'RPC Nodes',
      nodesAmount: '{number, plural, =1 {# Node} other {# Nodes}}',

      generalInfo: 'General Info',
      chainId: 'Rollup Chain ID',
      l1Chain: 'L1 Chain',
      duration: 'Duration',
      creationDate: 'Creation Date',

      blockHeight: 'Block Height',
      RPCEndpoint: 'RPC Endpoint',
      RPCEndpoints: 'RPC Endpoints',
      sequencer: 'Sequencer',
      ankrManages: 'Managed by Ankr',
      sequencerURL: 'Sequencer URL',
      sequencerAddress: 'Seq. Address',
      bridge: 'Bridge',
      explorer: 'Explorer',

      showAll: 'Show All',

      package: 'Package',

      details: 'Details',
      DAL: 'Data Availability Layer',
      gasToken: 'Gas Token',

      pendingDepositAlert:
        'To finalize rollup deployment, you must make a security deposit of {amount} to your sequencer (subject to slashing rules).',
      depositButton: 'Deposit {amount}',
    },
    extendRollupModal: {
      title: 'Success',
      hint: 'You have successfully extended the testnet time by {duration} hours.',
      close: 'Close',
    },
  },
};
