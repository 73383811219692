import { makeStyles } from 'tss-react/mui';

export const useDeployRollupStepperStyles = makeStyles()(theme => ({
  root: {},
  list: {
    counterReset: 'step-counter',
    display: 'flex',
    flexWrap: 'nowrap',
    margin: 0,
    padding: theme.spacing(0, 2),
  },
  step: {
    counterIncrement: 'step-counter',
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.disabled,
    transitionProperty: 'border-color, color',
    transitionDuration: `${theme.transitions.duration.short}ms`,
  },
  stepCurrent: {
    color: theme.palette.primary.main,
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      height: 2,
      position: 'absolute',
      bottom: -1,
      left: 0,
      right: 0,
    },
  },
  stepPassed: {
    color: theme.palette.text.primary,
  },
  stepNumber: {
    '&::before': {
      content: 'counter(step-counter) "."',
      [theme.breakpoints.down('sm')]: {
        content: 'counter(step-counter)',
      },
    },
  },
  icon: {
    width: 20,
    [theme.breakpoints.down('md')]: {
      width: 16,
    },
  },
  stepTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
