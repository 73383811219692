import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { mainApi } from '../../../../api';
import { Gateways } from '../../../../apiService';
import { GRADE, IPlanItem } from '../../../RollupConst';
import { mapPlansFromApi } from '../utils/mapPlansFromApi';

interface IGetPlansProps {
  grade?: GRADE;
  stack?: string;
}

export const { useGetPlansQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getPlans: build.query<IPlanItem[], IGetPlansProps>({
      queryFn: queryFnNotifyWrapper<IGetPlansProps, never, IPlanItem[]>(
        async ({ grade, stack }) => {
          if (grade && stack) {
            const { data } = await Gateways.noAuth.api.get(
              `/api/v1/plans/${grade}/${stack}`,
              {
                method: 'GET',
              },
            );

            const mapped = mapPlansFromApi(data);

            return { data: mapped };
          }
        },
      ),
    }),
  }),
});
