// TODO: at the end all features should be activated
export const featuresConfig = {
  showLinksToNonExistPages: false,

  selfSequencerManageAvailable: false,

  showAnkrEthGasToken: false,

  showCustomGasTokenOptionInDeploymentForm: false,
};
