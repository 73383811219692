import React, { JSX, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { uid } from 'react-uid';
import { isIntegerPositive } from '@ankr.com/raas-utils';
import { ExternalLink, Question, TextField } from '@ankr.com/ui';
import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { validator } from 'web3';

import { featuresConfig } from '../../../../../common/const/features';
import { CUSTOM_GAS_TOKEN_DOCS_URL } from '../../../../../common/const/values';
import { getToken } from '../../../../../common/utils/getToken';
import { useTranslation } from '../../../../../i18n';
import {
  GAS_TOKEN,
  GAS_TOKEN_MAINNET,
  GAS_TOKEN_TESTNET,
  GRADE,
  IDeployRollupFormConfigurationPayload,
} from '../../../../RollupConst';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';

export function DeployRollupFormConfiguration(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const rollupDeployState = useRollupDeployState();

  const { dispatchData } = useDispatchRollupDeployData();

  const { control, handleSubmit, getValues, watch } =
    useForm<IDeployRollupFormConfigurationPayload>({
      defaultValues: {
        chainId: rollupDeployState.chainId,
        networkName: rollupDeployState.networkName,
        gasToken: rollupDeployState.gasToken,
        gasTokenAddress: rollupDeployState.gasTokenAddress,
        optionalSettings: rollupDeployState.optionalSettings,
      },
    });

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormConfigurationPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  const { grade } = rollupDeployState;

  const gasTokenList = useMemo(() => {
    return Object.entries(
      grade === GRADE.mainnet ? GAS_TOKEN_MAINNET : GAS_TOKEN_TESTNET,
    ).map(([key, value]) => {
      const { image, name } = getToken({
        value,
        imageClassName: classes.gasTokenIcon,
      });

      return {
        label: (
          <Box>
            <Box>
              {value !== GAS_TOKEN.custom && image}
              {name}
            </Box>
            {value === GAS_TOKEN.custom && (
              <Typography
                variant="body3"
                className={classes.textDisabled}
                component="div"
              >
                {t(keys.deployRollupConfigurationProps.customGasTokenHint)}
              </Typography>
            )}
          </Box>
        ),
        key,
      };
    });
  }, [
    classes.gasTokenIcon,
    classes.textDisabled,
    grade,
    keys.deployRollupConfigurationProps.customGasTokenHint,
    t,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="chainId"
              rules={{
                required: t('validation.required'),
                validate: {
                  isIntegerPositive: v =>
                    isIntegerPositive(Number(v)) ||
                    t('validation.require-positive-integer'),
                },
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="number"
                  helperText={
                    error
                      ? error.message
                      : t(keys.deployRollupConfigurationProps.chainIdHint)
                  }
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={
                    <Box className={classes.inputLabelWrap}>
                      {t(keys.deployRollupConfigurationProps.chainId)}
                      <Tooltip
                        title={t(
                          keys.deployRollupConfigurationProps.chainIdTooltip,
                        )}
                      >
                        <Question className={classes.questionIcon} />
                      </Tooltip>
                    </Box>
                  }
                  inputProps={{
                    step: '1',
                    min: '0',
                    inputMode: 'numeric',
                  }}
                />
              )}
            />
          </Box>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="networkName"
              rules={{
                required: t('validation.required'),
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  type="url"
                  label={t(keys.deployRollupConfigurationProps.networkName)}
                />
              )}
            />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionTitleWrap}>
            {grade === GRADE.mainnet && featuresConfig.showAnkrEthGasToken && (
              <Alert severity="info" className={classes.alertBlock}>
                {t(keys.deployRollupConfigurationToken.tokensHint)}
              </Alert>
            )}

            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {t(keys.deployRollupConfigurationToken.title)}
            </Typography>
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {grade === GRADE.mainnet &&
                featuresConfig.showAnkrEthGasToken &&
                t(keys.deployRollupConfigurationToken.subtitleMainnet)}
              {grade === GRADE.testnet &&
                t(keys.deployRollupConfigurationToken.subtitleTestnet)}
            </Typography>
          </Box>

          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="gasToken"
              rules={{
                required: t('validation.required-one'),
              }}
              control={control}
              render={({ field, fieldState }) => (
                <RadioGroup
                  name="gasToken"
                  className={classes.radioGroup}
                  value={field.value}
                >
                  {gasTokenList.map(item => {
                    if (
                      !featuresConfig.showCustomGasTokenOptionInDeploymentForm &&
                      item.key === GAS_TOKEN.custom
                    ) {
                      return null;
                    }

                    return (
                      <FormControlLabel
                        key={uid(item)}
                        control={
                          <Radio
                            {...field}
                            size="small"
                            value={item.key}
                            disableFocusRipple
                          />
                        }
                        label={item.label}
                      />
                    );
                  })}

                  {!!fieldState.error?.message && (
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </RadioGroup>
              )}
            />
          </Box>

          {!!CUSTOM_GAS_TOKEN_DOCS_URL && grade === GRADE.mainnet && (
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {t(keys.deployRollupConfigurationToken.wantAddTokenHint)}
              <Button
                variant="text"
                size="small"
                color="primary"
                endIcon={<ExternalLink />}
                href={CUSTOM_GAS_TOKEN_DOCS_URL}
                rel="noreferrer"
                target="_blank"
              >
                {t(keys.deployRollup.viewInstructions)}
              </Button>
            </Typography>
          )}

          {watch('gasToken') === GAS_TOKEN.custom && (
            <Box className={classes.sectionHalfWrap}>
              <Controller
                name="gasTokenAddress"
                rules={{
                  required: t('validation.required'),
                  validate: v =>
                    validator.isAddress(v ?? '') ||
                    t('validation.invalid-address'),
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t(
                      keys.deployRollupConfigurationProps.customGasTokenAddress,
                    )}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
