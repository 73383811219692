import React, { JSX, ReactNode } from 'react';
import { usePageTitle } from '@ankr.com/raas-hooks';
import { useIsMDDown } from '@ankr.com/raas-themes';
import { Box } from '@mui/material';

import { t } from '../../../i18n';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { useLayoutDefaultStyles } from './useLayoutDefaultStyles';

export interface ILayoutDefaultProps {
  children?: ReactNode;
  title?: string;
}

export function LayoutDefault({
  children,
  title,
}: ILayoutDefaultProps): JSX.Element {
  const { classes } = useLayoutDefaultStyles();

  const isBreakpointDown = useIsMDDown();

  usePageTitle({ projectName: t('project-name'), pageTitle: title });

  return (
    <Box className={classes.root}>
      <Header />
      <Box component="main" className={classes.main}>
        {!isBreakpointDown && (
          <Box className={classes.sideberWrap}>
            <Sidebar />
          </Box>
        )}
        <Box className={classes.mainContent}>{children}</Box>
      </Box>
    </Box>
  );
}
