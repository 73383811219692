import { makeStyles } from 'tss-react/mui';

export const useDashboardStyles = makeStyles()(theme => ({
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  value: {
    color: theme.palette.text.secondary,
  },

  idColumn: {
    flexGrow: 8,
  },
  statusColumn: {
    flexGrow: 4,
  },
  DALColumn: {
    flexGrow: 4,
  },
  dateColumn: {
    flexGrow: 5,
  },
  addColumn: {
    flexGrow: 5,
    justifyContent: 'flex-end',
  },

  stackLogo: {
    width: 40,
    height: 40,
  },

  blockchainLogo: {
    width: 32,
    height: 32,
  },
}));
