import { t } from '@ankr.com/raas-utils';

import { useLocaleMemo } from '../../i18n';
import { GAS_TOKEN, IDepositsForPlan } from '../../Rollup/RollupConst';

interface IUseDepositsForPlanProps {
  deposits?: IDepositsForPlan;
  gasTokenType?: GAS_TOKEN;
}

export function useDepositsForPlan({
  deposits,
  gasTokenType,
}: IUseDepositsForPlanProps): string | undefined {
  return useLocaleMemo(() => {
    if (!deposits || !gasTokenType) {
      return undefined;
    }

    const amountsWithToken =
      gasTokenType === GAS_TOKEN.custom
        ? deposits?.custom?.parsedForUI.amountsWithToken
        : deposits?.default?.parsedForUI.amountsWithToken;

    return amountsWithToken?.join(t('common.plus-sign-spaced'));
  }, [deposits, gasTokenType]);
}
